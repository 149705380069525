import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=089f15d8&scoped=true"
import script from "./Settings.vue?vue&type=script&lang=js"
export * from "./Settings.vue?vue&type=script&lang=js"
import style0 from "./Settings.vue?vue&type=style&index=0&id=089f15d8&prod&lang=less"
import style1 from "./Settings.vue?vue&type=style&index=1&id=089f15d8&prod&scoped=true&lang=less"
import style2 from "./Settings.vue?vue&type=style&index=2&id=089f15d8&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089f15d8",
  null
  
)

export default component.exports